import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { seo } from '../content/transport-branze'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import TemplateBody from '../components/TemplateBody'
import { useLangContext } from '../context/lang.context'
import { sCenteredSpaced } from '../style'
import Button from '../components/Button'
import { useFormContext } from '../context/form.context'

export default function IndustryTemplate({ data }) {
  const {
    name,
    title,
    slug,
    keywords,
    image,
    body,
    description,
    createdAt,
    updatedAt,
    industry,
  } = data.industry

  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const imageData = getImage(image)

  const buttonIntro = {
    text: 'Czytaj więcej',
    action: 'SCROLL',
  }

  const buttonBody = {
    text: 'Wyceń kontener',
    action: () => setFormOpen(true),
    glow: true,
  }

  const buttonIndustry = industry
    ? {
        text: `Więcej o branży ${industry.name.replace('Transport ', '')}`,
        link: `/${industry.slug}/`,
        dimmed: true,
      }
    : {
        text: `Inne branże`,
        link: `/branze/`,
        dimmed: true,
      }

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={slug}
        keywords={keywords}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <Intro
        h={1}
        title={name}
        desc={industry ? data.industry.description : seo.desc[lang]}
        image={imageData}
        button={buttonIntro}
      />
      <TemplateBody
        title={description}
        body={body.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        image={imageData}
        imageCredits={image?.description}
        buttons={[buttonIndustry, buttonBody]}
      />
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

const buttonBack = {
  text: {
    pl: 'Powrót do branż',
    en: 'Back to transport by industry',
  },
  link: '/branze/',
}

export const query = graphql`
  query ($id: String!) {
    industry: contentfulIndustry(id: { eq: $id }) {
      id
      createdAt
      updatedAt
      slug
      name
      title
      description
      keywords
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        description
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
        )
        file {
          url
          fileName
        }
      }
    }
  }
`
